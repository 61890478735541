<template>
  <span>
    <component v-if="category" :is="pageTemplate" :key="key"></component>
    <not-found v-else />
  </span>
</template>
<script>
import split from "lodash/split";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryTemplate from "@/mixins/categoryTemplate";
import NotFound from "@/components/NotFound.vue";
import UserService from "~/service/userService";
import Vue from "vue";
import AnalyticsService from "@/commons/service/analyticsService";
import customerLifetime from "@/commons/service/customerLifetime";
// import { runScript } from "~/service/ebsn";
// import { checkPopup } from "@/customEbsn";

export default {
  components: { NotFound },
  name: "Category",
  mixins: [categoryMixins, deliveryReactive, categoryTemplate],
  props: ["additionalParam"],
  data() {
    return {
      key: 1
    };
  },
  methods: {
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      this.key += 1;
    },
    async setLoginCookie() {
      if (this.$route.query.loginCompleted) {
        this.reload();
        let userData = await UserService.getUserDetail();
        if (userData && userData.email) {
          AnalyticsService.login(userData, "SAML");
          const customerLoginData = await customerLifetime.getCustomerLifetimeData(
            userData.userId
          );
          if (customerLoginData) {
            AnalyticsService.trackEvent(
              "userDataLoaded",
              Object.keys(customerLoginData),
              Object.values(customerLoginData)
            );
          }
          Vue.$cookies.set(
            "sfliveagentid",
            btoa(userData.email.trim().toLowerCase())
          );
        }
      }
    }
  },
  mounted() {
    this.setLoginCookie();
  }
};
</script>
